.timeline-container {
    padding: 0 50px 1px 50px;
    position: relative;
    width: 100vw;
    background: #080707;
}

.timeline-title {
    color: #fff;
    text-align: center;
    font-size: 69px;
}

/* Timeline Itself */
.timeline {
    width: 50%;
    position: relative;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background: #fff;
    top: 0;
    left: 100%;
    margin-left: -3px;
    z-index: 1;
}


/* Timeline Items */
.timeline-item {
    position: relative;
    max-width: 900px;
    margin: 40px;
    opacity: 1;
    z-index: 2;
}

.timeline-item:nth-child(even) {
    left: 100%;
}

.timeline-item:nth-child(even) img {
    left: -60px;
}

.timeline-contents {
    position: relative;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 15px;
}

.timeline-item-title {
    color: #000;
    font-weight: 600;
}

.timeline-item-date {
    color: #000;
    font-weight: 500;
}

.timeline-item-desc {
    color: #000;
    font-weight: 500;
}

.timeline-logo {
    position: absolute;
    width: 40px;
    border-radius: 50%;
    right: -60px;
    top: 20px;
}

.left-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 25px;
    z-index: 2;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #fff;
    right: -15px;
}

.right-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 25px;
    z-index: 2;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    left: -15px;
}

@media screen and (max-width: 960px) {
    .timeline-container {
        padding: 29px;
    }

    .timeline-contents {
        padding: 6%;
    }
}

@media screen and (max-width: 768px) {
    .timeline-container {
        padding: 20px;
    }

    .timeline-contents {
        padding: 3% 4%;
    }
    
    .timeline::after {
       left: 20px; 
    }

    .timeline-item {
        position: relative;
        width: 85vw;
        margin: 40px;
        opacity: 1;
        z-index: 2;
    }

    .timeline-contents {
        margin: 4%;
    }

    .timeline-item img {
        right: 100%
    }
    
    .timeline-item:nth-child(even) {
        left: 0px;
    }

    .timeline-item:nth-child(even) img {
        left: -40px;
    }

    .left-arrow {
        border-right: 15px solid #fff;
        border-left:0;
        left: -15px;
    }

}