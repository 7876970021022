.backtotop-container {
    position: fixed; 
    right: 1.5%;
    bottom: 40px;
    z-index: 10;
}

.backtotop-btn {
    width: 50px;
    height: 50px;
    padding-left: 18%;
    cursor: pointer;
    opacity: 60%;
    filter: invert(100%) sepia(6%) saturate(5%) hue-rotate(163deg) brightness(99%) contrast(107%);
}
