.about-container {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #080707;
    padding-bottom: 12px;
}

.about-title {
    padding-top: 4.5%;
    font-size: 69px;
    text-align: center;
    color: #fff;
}

.hello {
    color: #fff;
    padding: 0.2% 0.5%;
    font-size: 21px;
    font-weight: 500;
}

.about-description {
    position: relative;
    max-width: 90%;
    padding: 25px;
    border-radius: 5px;
    /* background: linear-gradient(to top, #395999, #2652ab 100%); */
    outline: 2px solid #fff;
    opacity: 95%;
    align-self: center;
}

.about-description > p {
    color: white;
    font-weight: 400;
}


@media screen and (max-width: 960px) {
    .about-title {
        padding-top: 10%;
    }
}

@media screen and (max-width: 768px) {
    .about-title {
        padding-top: 12%;
    }

}