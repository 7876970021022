.smash-section-container {
    min-height: 100vh;
    height: 100%;
    /* display: flex; */
    padding: 1.5%;
    background-color: #080707;
    /* background-image: url('../../assets/images/stoneback.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.smash-section-title {
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    font-size: 36px;
}


.smash-links-container {
    display: flex;
    flex-wrap: wrap;
}

.smash-link {
    display: flex;
    width: 29rem;
    height:min-content;
    padding: 1%;
    margin: 0 auto;
}

.link-container {
    width: 100%;
    text-decoration: none;
}

.smash-link:hover {
    background-color: #395999;
    border-radius: 5px;
}

.smash-link-title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    padding-bottom: 5%;
    color: #fff;
}

.smash-link-img {
    width: 100%;
    padding: 5% 0 5% 0;
}