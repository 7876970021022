.contact-modal-container {
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: 99;
}

.outer-modal {
    position: fixed;
    width: 100vw;
    height: 100%;
    opacity: 95%;
    background-color: #080707;
}

.inner-modal {
    position: fixed;
    display: flex;
    width: 80%;
    opacity: 100%;
    background-color: #395999;
    border-radius: 5px;
    flex-direction: column;
    margin: 10%;
}

.inner-modal h2{
    position: relative;
    text-align: center;
    color: #fff;
}

.close-modal {
    position: absolute;
    background: none;
    color: #fff;
    border: #fff solid 2px;
    border-radius: 5px;
    padding: 0.5%;
    margin: 1%;
    right: 6px;
    cursor: pointer;
}

.close-modal:hover {
    opacity: 75%;
}

.contact-modal-title {
    color: #fff;
    text-align: center;
    padding: 1.5% 0 0.5% 0;
    border-bottom: #fff solid 1px;
    margin: 0 30% 0 30%;
}

.contact-form {
    position: relative;
    margin: 2%;
}

.form-container {
    position: relative;
    display: flex;
    padding: 0.2%;
}

.form-title {
    position: relative;
    display: flex;
    width: 25%;
    font-weight: 500;
}

.form-control {
    position: relative;
    width: 75%;
    padding: 0.7%;
    margin: 2px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}

.contact-send-btn {
    font-size: large;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: none;
    border: #fff solid 3px;
    border-radius: 5px;
    padding: 0.5%;
    margin: 1.5% auto 0%;
    cursor: pointer;
}

.contact-send-btn:hover {
    opacity: 75%;
}

@media screen and (max-width: 768px) {
    .contact-form {
        margin: 2% 5%;
    }

    .form-title {
        text-align: left;
    }

    .form-container {
        flex-direction: column;
    }

    .form-title, .form-control {
        width: 100%;
    }

    .contact-send-btn {
        padding: 2%;
    }

    .contact-modal-title {
        margin: 0 10% 0 10%;

    }
}

@media screen and (max-width: 960px) {
    .form-title {
        font-size: medium;
    }

    .contact-send-btn {
        padding: 2%;
    }
}
