* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Orbitron', sans-serif;
  }

  html, body {
    overflow-x: hidden;
  }

  @media screen and (max-width: 960px) {
    body {
      overflow-x: hidden;
    }
}