.smashvods-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 1%;
  padding-bottom: 2%;
  background-color: #080707;
  box-sizing: border-box;
}

.smashvod-title {
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 64px;
  border-bottom: #fff solid 1px;
  margin: 0 25%;
  margin-bottom: 1%;
}

.yt-video-gallery {
  display: flex;
}

.yt-video-container {
  width: 50%;
}

.video-container {
  position: relative; 
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  width: 900px auto;
  outline: #fff solid 3px;
  border-radius: 5px;
}

.yt-video-iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}

.video-title {
  height: 100px;
  position: relative;
  color: #fff;
  text-align: center;
  font-weight: 500;
  line-height: 29px;
  font-size: 32px;
  margin: 0.5%;
  padding: 1.2% 0;
  border-bottom: #fff solid 1px;
}

.vod-list-container {
  width: 50%;
  border: #fff solid 2.5px;
  border-radius: 5px;
  margin: 0.5%;
}

.vod-list-item {
  display: flex;
  margin: 0 2%;
}

.vod-selection {
  position: relative;
  margin: 0.5%;
  padding: 0.5%;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.vod-selection:hover {
  background-color: #fff;
  opacity: 98%;
  color: #080707;
  border-radius: 5px;
}

.vod-soon {
  color: #fff;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .smashvods-container {
    height: auto;
  }

  .yt-video-gallery {
    flex-direction: column;
  }

  .yt-video-container, .vod-list-container {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1%;
  }
}

@media screen and (max-width: 960px) {
  .video-title {
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .smashvod-title {
    font-size: x-large;
    margin-bottom: 2%;
  }

  .video-title {
    line-height: 100%;
    height: auto;
    font-size: large;
  }

  .vod-selection {
    font-size: medium;
  }
}