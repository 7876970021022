.fightstickinfo-container {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: #080707;
    padding: 2%;
    padding-bottom: 5%;
}

.fightstickinfo-title {
    color: #fff;
    font-weight: 600;
    font-size: 69px;
    text-align: center;
}

.fightstickinfo-subtitle {
    color: #fff;
    font-size: 36px;
    text-align: center;
    padding: 1%;
}

.fightstickinfo-box {
    position: relative;
}

.fightstickinfo-desc {
    color: #fff;
}

.fightstickinfo-img-container {
    display: flex;
    width: auto auto;
    flex-wrap: wrap;
    /* margin: 0 auto; */
}

.fightstickinfo-img {
    max-width: 500px;
    padding: 0.5%;
    width: 100%;
    margin: 0 auto;
    animation: grow 1.5s ease-in forwards;
    border-radius: 10px;
}
@keyframes grow {
    0%{
        transform: scale(0%);
        opacity: 0;
    }
    100%{
        transform: scale(100%);
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .fightstickinfo-title {
        font-size: 48px;
    }

    .fightstickinfo-subtitle {
        font-size: 29px;
    }

    .fightstickinfo-img-container {
        padding: 1%;
    }
}