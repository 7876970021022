.footer-container {
    width: 100vw;
    position: relative;
    background: linear-gradient(90deg, #1c1b1b 0%, #1a1717 100%);
}

.row {
    padding: 2px;
    display: flex;
}

.col {
    padding: 1.2% 0% 0% 7%;
    width: 33.33%;
}

.footer-list {
    padding: 1%;
}

footer.link {
    position: relative;
    display: flex;
    padding: 5%;
}

.footer-title {
    color: #fff;
    font-weight: 600;
}

.footer-item {
    display: flex;
    color: #fff;
    list-style: none;
    padding: 2% 0% 2% 3%;
}

.footer-item a {
    display: flex;
    text-decoration: none;
    cursor: pointer;
}

.footer-item a:visited {
    color: #fff;
    text-decoration: none;

}

.footer-item a:hover {
    opacity: 78%;
    text-decoration: none;
    transition: all 0.2s ease-out;
}

.footer-item a:active {
    color: #fff;
    text-decoration: none;
}

.footer-item a:link {
    color: #fff;
    text-decoration: none;
}

.footer-contact-btn {
    position: relative;
    display: flex;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border: 0;
    width: 100% 100%;
}

.footer-contact-btn:hover {
    opacity: 78%; 
}

.footer-contact-btn h4 {
    font-size: larger;
    padding-top: 0.4%;

}

.footer-item h4 {
    font-weight: 300;
    padding-top: 0.4%;
    padding-right: 6px;
    padding-left: 2px;
    margin-top: 2px;
}

.footer-icon {
    width: 29px;
    filter: invert(100%) sepia(6%) saturate(5%) hue-rotate(163deg) brightness(99%) contrast(107%);
}

@media screen and (max-width: 768px) {
    .row {
        display: flex;
        flex-direction: column-reverse;
    }

    .col {
        width: 80%;
        align-self: center;
        padding: 0.2%;
    }

    .footer-list {
        padding: 0%;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
    }

    .footer-title {
        display: none;
    }

    .footer-item {
        align-self: center;
        display: inline-block;
        padding: 1% 8%;
    }

    .footer-item a {
        padding-top: 1.2%;
    }

    .footer-contact-btn h4 {
        font-size: larger;
        padding: 2%;
        margin-right: 5px;
        /* border: #fff solid 2px; */
        border-radius: 5px;
    }
}