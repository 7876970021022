.smashstages-container {
    position: relative;
    width: 100vw;
    height:150vh;
    padding-bottom: 2%;
    background-color: #080707;
    padding: 1%;
}

.collapsable {
    max-width: 100vw;
    overflow: hidden;
}

#collapsable-head {
    position: absolute;
    left:-10%;
}

.collapsable-content {
    height: 0px;
    overflow: hidden;
    border-radius: 4px;
    line-height: 1.4;
    position: relative;
    top: -100%;
    opacity: 0;
    transition: all 500ms ease-in-out;
    z-index: 1;
}

.collapsable input:checked ~ .collapsable-content {
    height: 100%;
    padding-bottom: 29px;
    opacity: 1;
    top: 0;
}

.smashstages-title {
    padding: 1%;
    margin: 0 3% 0 3%;
    max-width: 100%;
    color: #fff;
    border: 3px solid #fff;
    box-shadow: 0 5px 12px 5px #1a1717, 0 2px 8px 0 #fff;
    border-radius: 5px;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 49px;
    font-weight: 900;
    text-align: center;
}

.smashstages-title:hover {
    color: #080707;
    background: #fff;
    opacity: 95%;
    transition: all 400ms ease;
}

.smashstages-container p {
    padding: 0% 5%;
    color: #fff;
    text-align: center;
}

.tsc-reference,  .tsc-reference:visited,  .tsc-reference:link {
    color: #fff;
    text-align: center;
}


/* Stage Image Gallery */
.stageimgs-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 2% 5%;
}

.gallery-icons {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
}

.stageimg-item {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.stageimg-img {
    width: 100%;
}

.stageimg-img:hover {
    outline: #fff solid 1px;
    padding: 2px;
    cursor: pointer;
}

.main-gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: #fff solid 2px;
    margin: 1%;
}

.main-gallery-title {
    color: #fff;
    font-size: 29px;
    text-align: center;
    margin-top: 1%;
}

.main-gallery-image {
    max-width: 900px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}


@media screen and (max-width: 960px) {
    .gallery-icons {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .smashstages-title {
        font-size: 29px;
    }

    .stageimg-item {
        width: 85px;
        height: 85px;
        margin: auto;
    }

    .main-gallery-title {
        font-size: 100%;
    }

    .gallery-icons {
        width: 80%;
    }
}