.projects-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #080707;
    overflow-x: hidden;
}

.projectsection-title {
    text-align: center;
    color: #fff;
    font-size: 64px;
}

.projects-container p {
    color: #fff;
    padding-top: 2%;
    text-align: center;
}

.git-container {
    position: relative;
    display: flex;
    max-width: 220px;
    border-radius: 5px;
    padding: 1%;
    margin: 1% auto;
}

.git-link {
    position: relative;
    width: 50%;
}

.git-link:hover {
    opacity: 85%;
}

.git-logo {
    position: relative;
    padding: 1%;
    margin: 0 auto;
    width: 100%;
}

.git-desc {
    padding-top: 10%;
    padding-left: 5%;
    font-size: 25px;
    width: 50%;
    color: #fff;
    text-align: center;
}

/* Project Modules */
.project-modules {
    width: 100% auto;
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
}

.project-modules-item {
    border-top: #fff solid 12px;
    border-bottom: #fff solid 2px;
    border-left: #fff solid 2px;
    border-right: #fff solid 2px;
    border-radius: 5px;
    width: 30%;
    padding: 2%;
    margin: 0 auto;
    margin-bottom: 10px;
}

.proj-img-box {
    width: 100% auto;
}

.proj-img {
    display: block;
    margin: auto;
    border: #fff solid 2px;
    border-radius: 5px;
}

.proj-title {
 color: #fff;
}

.proj-desc {
    color: #fff;
    font-weight: 400;
}

.proj-link {
    color: #fff;
}

@media screen and (max-width: 960px) {
    .project-modules-item {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    .projectsection-title {
        font-size: 49px;
    }

    .project-modules {
        padding: 4%;
    }

    .project-modules-item {
        width: 80%;
    }

    .proj-title {
        font-weight: 500;
    }

    .git-container {
        max-width: 120px;
    }

    .git-desc {
        font-size: 18px;
        padding-top: 5%;
        padding-left: 5%;
    }
}