.hero-video-container {
    width: 100%;
    height: 100vh;
    background-color: #080707;
}

.hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    scroll-margin-top: 100px;
}

.hero-text {
    position: absolute;
    z-index: 5;
}

.hero-text > h1 {
    color: #fff;
    padding-top: 290px;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    -webkit-text-stroke: 0;
    animation: comedown 1s forwards linear;
}

.hero-text > p {
    color: #fff;
    font-size: 34px;
    text-align: center;
    margin-top: 10px;
    z-index: 1;
    -webkit-text-stroke: 0;
    animation: comedown 1s forwards linear;

}
@keyframes comedown {
    0%{
        opacity: 0;
        transform: translateY(-50px)
    }
    80%{
        transform: translateY(3px)
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}

@media screen and (max-width: 960px) {
    .hero-text > h1 {
        padding-top: 220px;
        font-size: 70px;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .hero-text > h1 {
        padding-top: 75%;
        margin: 1%;
        font-size: 50px;
        text-align: center;
        -webkit-text-stroke-width: 0;
    }

    .hero-text > p {
        font-size: 29px;
        text-align: center;
        padding: 0% 2%;
        -webkit-text-stroke: 0;
    }
}